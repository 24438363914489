import React from "react"
import { PageLayout, PageTitle } from "../components"

export default () => {
  return (
    <PageLayout>
      <PageTitle>404: Not Found</PageTitle>
    </PageLayout>
  )
}
